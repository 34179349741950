// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")



import "bootstrap"
import "../stylesheets/application"

import $ from 'jquery';

global.$ = jQuery;

require("summernote")
require("vendors/video")
require("vendors/chosen")

document.addEventListener("turbolinks:load", () => {

  

  $('#preloader').fadeOut('slow', function () {
      $(this).remove();
  });


  $(window).on("scroll", function() {
      $(this).scrollTop() > 150 ? $(".back_top").fadeIn() : $(".back_top").fadeOut()
  });

  $(".back_top").on("click", function() {
      return $("html, body").animate({
          scrollTop: 0
      }, 1e3), !1
  });  

  $('[data-toggle="tooltip"]').tooltip();
  $('.btn-info').tooltip();
 	$('[data-toggle="popover"]').popover();

	$('.summernote').summernote({
    	height: 365,
    	toolbar: [
                ['style', ['clear', 'bold', 'italic', 'underline']],
                ['Paragraph style', ['ol', 'ul']],
                ['Insert', ['link', 'picture', 'videoAttributes']],
                ['Misc', ['codeview']]
            ]
	});


  $.fn.extend({
      toggleText: function(a, b){
          return this.text(this.text() == b ? a : b);
      }
  });

  $('.topright').on('click', '.on-off', function(e){

    $(this).toggleClass('on');
    $(this).toggleText('Planlegg', 'Planlagt');

    var a = parseInt($('.a-counter').text());
    //console.log('teller: ' + a)

    if ($(this).hasClass('on')? a++ : a--) 
    console.log('teller: ' + a)
    $('.a-counter').text(a);

  })

  if($('textarea[id=message]').val().trim()==''){
     $(".send-message").attr("disabled", true);  
  }

  $('textarea[id=message]').on('keyup', function(){
      $(".send-message").attr("disabled", true);
      var val = $(this).val().trim();    
      if(val.length > 0){
          $(".send-message").attr("disabled", false);
      }
  });

  $(".send-message").on('click', function(){
    var content = $('#message').val();
    $('.send-message').find('i').addClass('fa-spinner fa-spin');
      $.ajax({
          type: "POST",
          url: "/messages",
          dataType: 'json',
          data: { message: {
              content: content
                  
          } },
          success: function(data) {
            $('.send-message').find('i').removeClass('fa-spinner fa-spin');
            $('.send-message').attr("disabled", true);

            $('#message').val('');

            var rec = '<p class="text-green">Tusen takk for din tilbakemelding!</p>'
  
            $('.reciept').append(rec);

            return false;
          },
          error(data) {
              alert('Feil');
              
              return false;
          }
      })  
  });        


})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
